<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="标题" prop="title">
        <el-input v-model="info.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="分类" prop="pid" v-if="!info.id || info.pid>0">
        <el-select v-model="info.pid" placeholder="请选择" clearable>
          <el-option
              v-for="item in menuList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="图片" prop="img">
        <SelectImgs :selectNum="1" :selectData="[info.img]" :selectFunc="picChoose" :idx="1"></SelectImgs>
        <div style="margin-top: 40px">230*230px</div>
      </el-form-item>
      <el-form-item label="背景图片" prop="backimg">
        <SelectImgs :selectNum="1" :selectData="[info.backimg]" :selectFunc="picChoose" :idx="2"></SelectImgs>
        <div style="margin-top: 40px">297*297px</div>
      </el-form-item>
      <el-form-item label="简介">
        <el-input type="textarea" rows="4" v-model="info.intro" placeholder="请输入简介"></el-input>
      </el-form-item>
      <el-form-item label="图标" prop="pics">
        <el-button type="primary" @click="addChar()">新增</el-button>
        <ul class="picList mg-tp-10">
          <li class="item" style="width: 104px;" v-for="(item,index) in info.pics" :key="index">
            <div class="img">
              <SelectImgs :selectNum="1" :selectData="[item.img]" :selectFunc="charChoose" :idx="index"></SelectImgs>
            </div>
<!--            <el-input type="textarea" rows="3" v-model="item.title" placeholder="请输入标题"></el-input>-->
            <div class="spot" @click="delChar(index)"><i class="el-icon-delete"></i></div>
          </li>
        </ul>
      </el-form-item>
      <el-form-item label="排序" prop="ordering">
        <el-input v-model="info.ordering" placeholder="请输入序号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
export default {
  data() {
    return {
      menuList: [],
      info: {
        id: 0,
        pid: '',
        title: '',
        img: '',
        intro: '',
        pics: [],
      },
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
      }
    };
  },
  created() {
    this.getMenuList()
    if(this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
  },
  components: {
    SelectImgs
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    picChoose(item,type) {
      if(type==1) {
        this.info.img = item.pic
      } else {
        this.info.backimg = item.pic
      }
    },
    addChar() {
      this.info.pics.push({
        img: '',
        title: ''
      })
    },
    delChar(idx) {
      this.info.pics.splice(idx,1)
    },
    charChoose(item,idx) {
      this.info.pics[idx].img = item.pic
    },
    getMenuList() {
      var that = this
      this.$api.goods.goodsMenuIndex({},function (res) {
        if(res.errcode == 0) {
          that.menuList = res.data
        }
      })
    },
    getInfo() {
      var that = this
      this.$api.goods.goodsMenuInfo({id: that.info.id}, function (res) {
        if(res.errcode == 0) {
          that.info = res.data
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = that.info
          this.$api.goods.goodsMenuEdit(param,function (res) {
            if(res.errcode == 0) {
              that.success(res.errmsg)
              that.reBack()
            } else {
              that.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style>
.widt .default {
  float: unset !important;
  margin: unset !important;
}
</style>
<style scoped>
.box {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  min-height: 500px;
}
</style>
